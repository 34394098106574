import $ from 'jquery';
import { setHidpiBackgroundImage } from '../utils/hidpi-background-image';
import { checkWebpSupport } from '../utils/webp';
import GaTracking from '../core/components/ga-tracking';
const ELEMENT_SELECTOR = '.js__product-color-select';
const COLOR_SELECT_ITEM_SELECTOR = '.js__color-select-item';
export default class ProductColorSelect {
    static isGaTrackingEnabled = false;
    static $colorSelectInput = $(ELEMENT_SELECTOR);
    static colorMapping = {
        new: 'badge-accent',
        limited_edition: 'badge-blue',
        pre_order: 'badge-yellow',
        out_of_stock: 'badge-error-red badge-outline',
        bundle: 'badge-amber',
        sale: 'badge-deep-red',
        available_with_trade_in: 'badge-accent',
        recycled: 'badge-amber',
        most_popular: 'badge-accent',
    };
    static init($colorSelect = $(ELEMENT_SELECTOR)) {
        this.$colorSelectInput = $colorSelect;
        if (this.$colorSelectInput.length) {
            this.bindEvents();
            this.initLabelsDisplay();
            this.initGaEventsTracking();
        }
        $('.js__product-color-select-wrapper .js__product-image-label').each((index, element) => {
            this.setImageLabel($(element));
        });
    }
    static initLabelsDisplay() {
        const $inputs = $(`${COLOR_SELECT_ITEM_SELECTOR}:first-child ${ELEMENT_SELECTOR}`);
        $inputs.each((index, input) => {
            this.displayLabels($(input));
        });
    }
    static initGaEventsTracking() {
        if (this.isGaTrackingEnabled) {
            return;
        }
        $('body').on('change', ELEMENT_SELECTOR, e => {
            const $input = $(e.currentTarget);
            const dataLayer = $input.data('data-layer');
            if (dataLayer) {
                GaTracking.pushDataLayer(dataLayer);
            }
        });
        this.isGaTrackingEnabled = true;
    }
    static displayLabels($input) {
        const $inputWrapper = $input.closest('.js__product-color-select-wrapper');
        const $labelWrapper = $inputWrapper.find('.js__product-labels');
        if (!$labelWrapper.length) {
            return;
        }
        const $labelTranslations = $('.js__label-translations');
        $labelWrapper.empty();
        const labels = ($input.data('labels') && $input.data('labels')) || '';
        const labelArray = labels.length > 0 ? labels.split(', ') : [];
        if (labelArray.length > 0 && $labelTranslations.length) {
            labelArray.forEach((label) => {
                $labelWrapper.append(`<li class="badge ${this.colorMapping[label]}">${$labelTranslations.data('labels')[label]}</li>`);
            });
        }
    }
    static bindEvents() {
        this.$colorSelectInput.on('change', e => {
            const $input = $(e.currentTarget);
            const $inputWrapper = $input.closest('.js__product-color-select-wrapper');
            const $firstImage = $inputWrapper.find('.js__product-first-image');
            const $secondImage = $inputWrapper.find('.js__product-second-image');
            this.displayLabels($input);
            $firstImage.siblings('source[type="image/webp"]').attr('srcset', $input.data('first-image-webp'));
            $firstImage.attr('src', $input.data('first-image'));
            $firstImage.siblings('source').first().attr('srcset', $input.data('first-image'));
            if ($input.data('second-image')) {
                $secondImage.siblings('source[type="image/webp"]').attr('srcset', $input.data('second-image-webp'));
                $secondImage.attr('src', $input.data('second-image'));
                $secondImage.siblings('source').first().attr('srcset', $input.data('second-image'));
                $firstImage.parent().removeClass('second-image-disabled');
                $secondImage.parent().removeClass('second-image-disabled');
            }
            else {
                $firstImage.parent().addClass('second-image-disabled');
                $secondImage.parent().addClass('second-image-disabled');
            }
            this.setImageLabel($input);
        });
    }
    static async setImageLabel($input) {
        const $inputWrapper = $input.closest('.js__product-color-select-wrapper');
        const $imageLabel = $inputWrapper.find('.js__product-image-label');
        const $labelWrapper = $inputWrapper.find('.js__product-labels');
        const isWebpSupported = await checkWebpSupport();
        const $imageLabelUrl = $input.data('image-label' + (isWebpSupported ? '-webp' : ''));
        const $imageLabel2xUrl = $input.data('image-label-2x' + (isWebpSupported ? '-webp' : ''));
        if ($imageLabelUrl && $imageLabel2xUrl) {
            setHidpiBackgroundImage($imageLabel, $imageLabelUrl, $imageLabel2xUrl);
            $labelWrapper.hide();
        }
        else {
            $imageLabel.css('background-image', '');
            $labelWrapper.show();
        }
    }
}
